import book1 from "../images/aktuelles1.png";
import book2 from "../images/aktuelles2.png";
import book3 from "../images/aktuelles3.png";
import book4 from "../images/aktuelles4.png";
import book5 from "../images/aktuelles5.png";

const Aktuelles = () => {
  return (
    <div className='aktuelles'>
      <h1 className='section-title title-aktuelles'>Aktuelles</h1>

      <div className='card new-display'>
        <h2>Kurzfassung der Distanzierung von Helmut Kentler</h2>
        <div
          className='aktuelles-description'
          id='distanzierung-kentler'
          style={{ textAlign: "left" }}>
          <p>
            Trotz der Abgrenzungen vom Sexualpädagogen Helmut Kentler, die ich
            als Sexual- und Erziehungswissenschaftler in verschiedenen Texten
            meinerseits vorgenommen habe, werde ich von diversen Gegner*innen
            einer wissenschaftlich fundierten Sexualpädagogik immer noch in der
            Nähe seiner Position und seines Wirkens verortet.
          </p>
          <p>
            Aus diesem Grund bringe ich meine Position gegenüber Kentler und die
            Verurteilung seines unverantwortlichen Verhaltens im sogenannten
            „Berliner Experiment“ hier noch einmal deutlich auf den Punkt:
          </p>
          <ol style={{ display: "flex", flexDirection: "column", gap: "20px" }}>
            <li>
              Helmut Kentler verstieß mit seinem Versuch, jugendliche Ausreißer
              und Trebegänger bei pädophilen Männern unterzubringen, gegen jede
              auch damals geltende Ethik sozial- und sexualpädagogischen
              Verhaltens. Seine Aktivitäten waren eindeutig illegal und entzogen
              sich dem wissenschaftlichen Diskurs im breiteren
              Kolleg*innenkreis.
            </li>
            <li>
              Kentler vermischte sein inzwischen offenbar gewordenes eigenes
              pädosexuelles Begehren mit dem sozialpädagogischen Tun und seiner
              sexualpädagogischen Haltung als einflussreicher Wissenschaftler.
              Das ist ethisch unzulässig und praktisch kriminell angesichts der
              damit verbundenen Legitimation pädosexueller Verbrechen.
            </li>
            <li>
              Kentler ermöglichte pädophilen Gruppen, sich als Teil einer
              Emanzipationsbewegung zu verstehen, obwohl die damit verbundenen
              Aktivitäten den Rechten und dem Schutz von Kindern und
              Jugendlichen diametral entgegenstehen. Damit wurde das Anliegen
              der emanzipatorischen Erziehungswissenschaft nachhaltig
              beschädigt.
            </li>
            <li>
              Kentler verkannte eklatant das potentielle Machtgefälle zwischen
              Erwachsenen und Kindern sowie Jugendlichen, insbesondere jener,
              die in prekären Lebenslagen befanden. Als wissenschaftlich
              arbeitender Pädagoge hätte er mit einem weiten Gewaltbegriff
              vertraut sein müssen. Auch hier hat seine persönliche sexuelle
              Präferenz offenbar den Blick auf die Realität unzulässig
              verstellt.
            </li>
            <li>
              Kentler nahm bei seinem Eintreten für die Sexualität von Kindern
              und Jugendlichen keine durchgehend deutliche Abgrenzung von
              Kinder- und Erwachsenensexualität vor, welche die Unzulässigkeit
              der Überwindung von Generationsschranken begründet. Das war und
              ist sexualwissenschaftlich und pädagogisch falsch.
            </li>
          </ol>
          <p>
            Helmut Kentler hat mit diesen wissenschaftlich unredlichen und
            illegal-kriminellen Praktiken der Sozial- und Sexualpädagogik großen
            Schaden zugefügt, insbesondere jener Theorie und Praxis, die sich
            auch heute noch an den Prinzipien der Aufklärung, der Bemündigung,
            Emanzipation und sexuellen Selbstbestimmung von Menschen ausrichtet.
          </p>
          <p style={{ textAlign: "right" }}>
            <strong>Prof. Dr. Uwe Sielert</strong>
          </p>
        </div>
      </div>

      <div
        id='sielert-gespraech-mit-katrin'
        className='card new-display '>
        <div className='border-card'>
          <div className='aktuelles-description'>
            Uwe Sielert im Gespräch mit Katrin Heise vom Deutschlandradio
            Kultur: Sexuelle Bildung gegen sexualisierte Gewalt und
            sexualpädagogische Materialien im öffentlichen Diskurs sowie
            Stationen der Karriere eines Erziehungswissenschaftlers
          </div>
          <a
            rel='noopener noreferrer'
            target='_blank'
            className='source source-aktuelles hyphens with-image'
            href='https://www.deutschlandfunkkultur.de/sexualpaedagoge-uwe-sielert-mit-bildung-gegen-sexualisierte-gewalt-dlf-kultur-48ca95ac-100.html'>
            Gespräch mit Katrin Heise vom Deutschlandradio Kultur
          </a>
        </div>

        <div className='aktuelles-description'>
          Der Begriff Sexualpädagogik wird sowohl im populären Sprachgebrauch
          als auch in fachlichen Kontexten sehr unterschiedlich gehandhabt.
          Wissenschaftliche und professionelle Diskurse benötigen jedoch
          möglichst eindeutige Begriffe. Der Beitrag definiert Sexualpädagogik
          als wissenschaftliche Disziplin. Zielgruppen sind die wachsende Gruppe
          der praktisch und konzeptionierend tätigen Fachkräfte sowie forschend
          und theoretisch Interessierte im weiten Bereich der sexuellen Bildung.
        </div>
        <div className='aktuelles-image-group mb-5'>
          <a
            rel='noopener noreferrer'
            target='_blank'
            className='source source-aktuelles hyphens with-image'
            href='https://drive.google.com/file/d/1XxGnWZFT5eIYajcTjQpYtLxpM7OXnrBJ/view?usp=sharing'>
            <img
              className='aktuelles-image'
              src={book5}
              alt='arbeitshilfe buch'
            />
          </a>
          <a
            rel='noopener noreferrer'
            target='_blank'
            className='source source-aktuelles hyphens'
            href='https://drive.google.com/file/d/1XxGnWZFT5eIYajcTjQpYtLxpM7OXnrBJ/view?usp=sharing'>
            Download PDF
          </a>
        </div>
        <div className='aktuelles-description'>
          Im Auftrag des Landesjugendamts Rheinland habe ich zusammen mit dem
          Sexualpädagogen und Organisationsberater Reiner Waniliek aus Wiesbaden
          Ende 2021 eine Arbeitshilfe zur Gestaltung von Sexualkultur in
          Organisationen entwickelt, die noch in diesem Jahr (2022) aufgrund
          verschiedener Workshops mit Fachkräften aus Jugendhilfe und
          Gesundheitsdiensten weiterentwickelt wird:
        </div>
        <div className='aktuelles-image-group mb-5'>
          <a
            rel='noopener noreferrer'
            target='_blank'
            className='source source-aktuelles hyphens with-image'
            href='https://www.lvr.de/media/wwwlvrde/jugend/service/arbeitshilfen/Arbeitshilfe_Sexualkultur_in_Organisationen_LVR_November_2021.pdf'>
            <img
              className='aktuelles-image'
              src={book1}
              alt='arbeitshilfe buch'
            />
          </a>
          <a
            rel='noopener noreferrer'
            target='_blank'
            className='source source-aktuelles hyphens'
            href='https://www.lvr.de/media/wwwlvrde/jugend/service/arbeitshilfen/Arbeitshilfe_Sexualkultur_in_Organisationen_LVR_November_2021.pdf'>
            https://www.lvr.de/media/wwwlvrde/jugend/service/arbeitshilfen/Arbeitshilfe_Sexualkultur_in_Organisationen_LVR_November_2021.pdf
          </a>
        </div>
        <div className='aktuelles-description'>
          In Vorbereitung ist das folgende Praxishandbuch, das im Kontext der
          Kieler Juniorprofessur „Sexualpädagogik zur Prävention sexualisierter
          Gewalt“ entstanden ist und die Disziplinen der sexuellen Bildung, der
          Prävention sexualisierter Gewalt und der Antidiskriminierungsarbeit
          miteinander ins Verhältnis setzt.
        </div>
        <div className='aktuelles-description'>
          <span className='fw-bold'>Erscheinungszeitpunkt: Oktober 2022</span>
        </div>
        <div className='aktuelles-image-group mb-5'>
          <img
            className='aktuelles-image'
            src={book2}
            alt='arbeitshilfe buch'
          />
        </div>
        <div className='aktuelles-description'>
          Das folgende Praxishandbuch zur sexuellen Bildung im Erwachsenenalter
          erschien 2022 bereits in der zweiten Auflage:
        </div>
        <div className='aktuelles-image-group mt-1 mb-5'>
          <a
            rel='noopener noreferrer'
            target='_blank'
            className='source source-aktuelles hyphens with-image'
            href='https://www.beltz.de/fachmedien/sozialpaedagogik_soziale_arbeit/produkte/details/48660-praxishandbuch-sexuelle-bildung-im-erwachsenenalter.html'>
            <img
              className='aktuelles-image'
              src={book3}
              alt='arbeitshilfe buch'
            />
          </a>
          <p>
            <a
              rel='noopener noreferrer'
              target='_blank'
              className='source hyphens source-aktuelles'
              href='https://www.beltz.de/fachmedien/sozialpaedagogik_soziale_arbeit/produkte/details/48660-praxishandbuch-sexuelle-bildung-im-erwachsenenalter.html'>
              https://www.beltz.de/fachmedien/sozialpaedagogik_soziale_arbeit/produkte/details/48660-praxishandbuch-sexuelle-bildung-im-erwachsenenalter.html
            </a>
          </p>
        </div>
        <div className='aktuelles-description'>
          Noch immer aktuell ist das Standardwerk zur Sexuellen Bildung mit
          historischen und systematischen Beiträgen zum Thema, Moraldiskursen,
          sexueller Vielfalt, sexueller Bildung im Lebenslauf, Gefahren- und
          Schutzdiskursen sowie der institutionellen Verankerung, der Didaktik
          und professionellen Entwicklung.
        </div>
        <div className='aktuelles-image-group mt-1 mb-5'>
          <a
            rel='noopener noreferrer'
            target='_blank'
            className='source source-aktuelles hyphens with-image'
            href='https://www.beltz.de/fachmedien/sozialpaedagogik_soziale_arbeit/produkte/details/4772-handbuch-sexualpaedagogik-und-sexuelle-bildung.html'>
            <img
              className='aktuelles-image'
              src={book4}
              alt='arbeitshilfe buch'
            />
          </a>
          <p>
            <a
              rel='noopener noreferrer'
              target='_blank'
              className='source source-aktuelles hyphens'
              href='https://www.beltz.de/fachmedien/sozialpaedagogik_soziale_arbeit/produkte/details/4772-handbuch-sexualpaedagogik-und-sexuelle-bildung.html'>
              https://www.beltz.de/fachmedien/sozialpaedagogik_soziale_arbeit/produkte/details/4772-handbuch-sexualpaedagogik-und-sexuelle-bildung.html
            </a>
          </p>
        </div>
      </div>
    </div>
  );
};

export default Aktuelles;

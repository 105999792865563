import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Header from "./components/Header";
import Footer from "./components/Footer";
import Home from "./pages/Home";
import Aktuelles from "./pages/Aktuelles";
import Kontakt from "./pages/Kontakt";
import AUFSozialpadagogik from "./pages/ArbeitsUndForschungsFelder/AUFSozialpadagogik";
import AUFSexualpadagogik from "./pages/ArbeitsUndForschungsFelder/AUFSexualpadagogik";
import AUFSexualkultur from "./pages/ArbeitsUndForschungsFelder/AUFSexualkultur";
import AUFSexuelleGesundheit from "./pages/ArbeitsUndForschungsFelder/AUFSexuelleGesundheit";
import PSozialpadagogik from "./pages/Publikationen/PSozialpadagogik";
import PSexualpadagogik from "./pages/Publikationen/PSexualpadagogik";
import PSexualkultur from "./pages/Publikationen/PSexualkultur";
import PSexuelleGesundheit from "./pages/Publikationen/PSexuelleGesundheit";
import Rezensionen from "./pages/Rezensionen";
import Berufstatigkeit from "./pages/Berufstatigkeit";
import Mitgliedschaften from "./pages/Mitgliedschaften";
import Ausbildung from "./pages/Ausbildung";

function App() {
  return (
    <Router>
      <Header />
      <Routes>
        <Route
          className='link'
          path='/'
          element={<Home />}
        />
        <Route
          className='link'
          path='/ausbildung'
          element={<Ausbildung />}
        />
        <Route
          path='/berufstatigkeit'
          element={<Berufstatigkeit />}
        />
        <Route
          path='/mitgliedschaften'
          element={<Mitgliedschaften />}
        />
        <Route
          path='/aktuelles'
          element={<Aktuelles />}
        />
        <Route
          path='/kontakt'
          element={<Kontakt />}
        />

        <Route
          path='/arbeits-und-forschungsfelder/sozialpadagogik'
          element={<AUFSozialpadagogik />}
        />
        <Route
          path='/arbeits-und-forschungsfelder/sexualpadagogik'
          element={<AUFSexualpadagogik />}
        />
        <Route
          path='/arbeits-und-forschungsfelder/sexualkultur'
          element={<AUFSexualkultur />}
        />
        <Route
          path='/arbeits-und-forschungsfelder/sexuelle-gesundheit'
          element={<AUFSexuelleGesundheit />}
        />
        <Route
          path='/publikationen/sozialpadagogik'
          element={<PSozialpadagogik />}
        />
        <Route
          path='/publikationen/sexualpadagogik'
          element={<PSexualpadagogik />}
        />
        <Route
          path='/publikationen/sexualkultur'
          element={<PSexualkultur />}
        />
        <Route
          path='/publikationen/sexuelle-gesundheit'
          element={<PSexuelleGesundheit />}
        />
        <Route
          path='/rezensionen'
          element={<Rezensionen />}
        />
      </Routes>
      <Footer />
    </Router>
  );
}

export default App;
